<template>
  <AuthWrapper v-loading.fullscreen.lock="pageLoading" class="notranslate">
    <ChangeTemporalPassword
      v-if="showWindowState"
      :ResetPhoneNumber="this.formData.phone"
      @needsRefresh="needsRefresh">
    </ChangeTemporalPassword>
    <div class="form_wrapper _____form__signin" v-else>
      <div class="inner____form__sign-in">
        <div class="form__header">
          <h2 class="">
            {{ $t("auth.login.header") }}
          </h2>
          <hr />
          <p class="notranslate">{{ $t("auth.login.login_sub_text") }}</p>
        </div>
        <div class="form">
          <div class="form_inner">
            <div class="alert alert-success" v-if="showResetMessage">
              <!-- An SMS containing your new password has been sent to your phone number -->
              {{ $t("auth.forgot_password.reset_successful") }}
            </div>
            <el-form
              :model="formData"
              :rules="rules"
              ref="ruleForm"
              @submit.prevent.native="checkEnter"
              @keyup.enter.native="submitForm('ruleForm')">
              <div
                class=""
                style="margin: 0px !important; padding: 0px !important">
                <el-form-item
                  style="margin: 0px !important; padding: 0px !important"
                  :label="$t('auth.login.form.phone.label')"
                  required="">
                </el-form-item>
              </div>

              <div class="form_input_container">
                <el-form-item prop="phone">
                  <vue-phone-number-input
                    v-model="formattedPhone"
                    :color="'#f79b31'"
                    clearable
                    size="lg"
                    class="input-model"
                    @update="handleCountryChange"
                    fetch-country
                    :preferred-countries="['GH', 'CI', 'SN', 'NG']"
                    :required="true"
                    :error="inputError"
                    :translations="{
                      countrySelectorLabel: $t(
                        'misc.phone_input.countrySelectorLabel'
                      ),
                      countrySelectorError: $t(
                        'misc.phone_input.countrySelectorError'
                      ),
                      phoneNumberLabel: $t('misc.phone_input.phoneNumberLabel'),
                      example: $t('misc.phone_input.example'),
                    }"></vue-phone-number-input>
                </el-form-item>
              </div>

              <div class="form_input_container">
                <el-form-item
                  :label="$t('auth.login.form.password.label')"
                  prop="password">
                  <el-input
                    show-password
                    type="password"
                    autocomplete="off"
                    :placeholder="$t('auth.login.form.password.placeholder')"
                    v-model="formData.password">
                  </el-input>
                </el-form-item>
              </div>
              <div v-if="errors" style="margin-top: 3em">
                <Alert :message="errors" :type="type" />
              </div>
              <div class="mt-2">
                <el-form-item>
                  <router-link
                    to="/forget-password"
                    class="account_link float-right">
                    {{ $t("auth.login.forgot_password") }}
                  </router-link>
                </el-form-item>
              </div>
              <div class="terms_and_conditons_container">
                <el-form-item>
                  <el-button
                    id="mNotify_form_submit_btn"
                    :loading="loading"
                    type="primary"
                    @click="submitForm('ruleForm')">
                    {{ $t("auth.login.header") }}
                  </el-button>
                </el-form-item>
              </div>
            </el-form>
          </div>
        </div>
        <div class="mnotify__form_upper_link">
          <p class="text_right_corner text-center">
            {{ $t("auth.footer.no_account") }}
            <router-link
              style="cursor: pointer"
              to="/signup"
              class="account_link">
              {{ $t("auth.footer.sign_up") }}
            </router-link>
          </p>
          <p class="text_right_corner" v-if="!isFrenchUser">
            <a class="support-link" href="tel:+233541509394">
              {{ $t("auth.footer.support") }}
            </a>
          </p>
          <a
            v-if="!isFrenchUser"
            class="text_right_corner support-link"
            href="https://mnotify.tawk.help/"
            target="_blank">
            {{ $t("auth.footer.view_guide") }}
          </a>
        </div>
      </div>
    </div>
  </AuthWrapper>
</template>
<script>
  import AuthWrapper from "./AuthWrapper.vue";
  import store from "@/state/store";
  import togglerCorporateStatus from "@/helpers/toggleCorporateStatus.js";
  import Storage from "../../services/storage";
  import ChangeTemporalPassword from "./ChangeTemporalPassword.vue";
  export default {
    components: {
      AuthWrapper: AuthWrapper,
      ChangeTemporalPassword: ChangeTemporalPassword,
    },
    data() {
      return {
        phoneData: {
          isValid: false,
        },
        inputError: false,
        showTemporalResetPane: false,
        triggerRefreshOnComponent: 0,
        formattedPhone: "",
        formData: {
          phone: "",
          password: "",
          country: "",
        },
        showPassword: false,
        showResetMessage: false,
        errors: "",
        loading: false,
        type: "alert-danger",
        pageLoading: false,
        rules: {
          phone: [
            {
              required: true,
              message: this.$t("auth.login.form.phone.required"),
              trigger: "change",
            },
            {
              pattern: /\+?\d+/g,
              message: this.$t("auth.login.form.phone.required"),
            },
            {
              min: 10,
              max: 13,
              message: this.$t("auth.login.form.phone.length"),
              trigger: "blur",
            },
          ],
          password: [
            {
              required: true,
              message: this.$t("auth.login.form.password.length"),
              trigger: ["blur", "change"],
            },
          ],
        },
        userLanguage: "",
        nonLoggedInCountry: "",
      };
    },
    mounted() {
      let restetData = store.getters["uistate/resetPasswordSuccesData"];
      if (restetData && restetData.phone) {
        this.formData.phone = restetData.phone;
        this.showResetMessage = true;
      }
      this.userLanguage = navigator.language.split("-")[0];
      this.nonLoggedInCountry =
        navigator.language.split("-")[1] === "CI" ? "CI" : "GH";
      // check if user has SSO and is first time login
      this.loginOmniChannel();
    },
    computed: {
      countryCode() {
        return store.getters["auth/countryCode"];
      },
      showWindowState() {
        return store.getters["auth/getresetWindowVisible"];
      },
      isFrenchUser() {
        return this.nonLoggedInCountry === "CI" || this.userLanguage === "fr";
      },
    },
    methods: {
      toggleShow() {
        this.showPassword = !this.showPassword;
      },
      handleCountryChange(payload) {
        this.phoneData = payload;
        return this.phoneData;
      },
      submitForm(formName) {
        this.errors = "";
        this.loading = true;
        const storage = new Storage();
        storage.clearSavedData("token");
        storage.clearSavedData("currentUser");
        storage.clearSavedData("auth.admin");

        if (this.phoneData.isValid == false) {
          this.loading = false;
          this.errors = this.$t("auth.login.form.phone.invalid");
          this.inputError = true;
          setTimeout(() => {
            this.inputError = false;
            this.errors = "";
          }, 5000);
          return;
        } else {
          // Set Country Name
          this.formData.country = this.countryCode.find(
            (country) => country.code == this.phoneData.countryCode
          ).name;
          // Set Phone Number
          this.formData.phone = `${this.phoneData.countryCallingCode}${this.phoneData.nationalNumber}`;
          // Set source if it exists
          const omniChannelUrl = new URLSearchParams(window.location.search);
          if (omniChannelUrl.has("source")) {
            this.formData.source = "omnichannel";
          }
          this.$refs[formName].validate((valid) => {
            if (valid) {
              store
                .dispatch("auth/login", this.formData)
                .then((response) => {
                  localStorage.setItem("userName", response.data.data.name);
                  localStorage.removeItem("profileSetup");
                  this.loading = false;
                  if (response) {
                    let {
                      data: { data },
                    } = response;
                    // Signed IN
                    this.$mixpanel.track("Sign In", {
                      Phone: this.formData.phone,
                      Country: this.formData.country,
                      "User Type":
                        data.name == 0
                          ? "Account not activated"
                          : data.profile_type == 1
                          ? "Corporate"
                          : "Individual",
                      "User Name": data.name,
                      "Access Level": data.access_level == 4 ? "Admin" : "User",
                      "User Status":
                        data.name !== 0
                          ? "Activated Account"
                          : "Account not activated",
                      referrer: document.referrer,
                    });
                    this.$mixpanel.identify(this.formData.phone);
                    this.$mixpanel.people.set({
                      $name: data.name,
                      $phone: `+${this.formData.phone}`,
                      $email: data.email,
                    });
                    if (data.country === "CI") {
                      this.$mixpanelFranco.track("Sign In", {
                        Phone: this.formData.phone,
                        Country: this.formData.country,
                        "User Type":
                          data.name == 0
                            ? "Account not activated"
                            : data.profile_type == 1
                            ? "Corporate"
                            : "Individual",
                        "User Name": data.name,
                        "Access Level":
                          data.access_level == 4 ? "Admin" : "User",
                        "User Status":
                          data.name !== 0
                            ? "Activated Account"
                            : "Account not activated",
                        referrer: document.referrer,
                      });
                      this.$mixpanelFranco.identify(this.formData.phone);
                      this.$mixpanelFranco.people.set({
                        $name: data.name,
                        $phone: `+${this.formData.phone}`,
                        $email: data.email,
                      });
                    }
                    let storage = new Storage();
                    if (data.is_active != 1) {
                      store
                        .dispatch("auth/resendActivationCode", {
                          phone: this.formData.phone,
                          otp_method: "sms",
                        })
                        .then(() =>
                          this.$router.push("/verify-account").catch((err) => {
                            if (err.name !== "NavigationDuplicated") throw err;
                          })
                        );
                      return;
                    }
                    if (data.access_level == 4) {
                      this.$router.push("/admin/users/all").catch((err) => {
                        if (err.name !== "NavigationDuplicated") throw err;
                      });
                      return;
                    }
                    if (data.profile_type == 1) {
                      togglerCorporateStatus(true);
                    } else {
                      togglerCorporateStatus(false);
                    }
                    // verify whether default workspace has been check

                    if (storage.getSavedState("defaultLoginType")) {
                      return this.$router.push("/overview");
                    }
                    this.$router.push("/login_type").catch((err) => {
                      throw err.message;
                    });
                  }
                })
                .catch((err) => {
                  console.log(err);
                  this.loading = false;
                  localStorage.removeItem("TempActivateNumber");
                  store.dispatch("auth/setNumberToVerify", "");
                  if (err?.response?.data == undefined) {
                    this.errors = this.$t("misc.network_error");
                  }
                  if (err.response && err.response.status === 404) {
                    this.errors = err.response.data.data.error.split(".")[1];
                  }
                  if (err.response && err.response.status === 480) {
                    store
                      .dispatch(
                        "auth/setNumberNeedToVerify",
                        this.formData.phone
                      )
                      .then(() => {
                        this.$router.push("/verify-account").catch((err) => {
                          if (err.name !== "NavigationDuplicated") throw err;
                        });
                      });
                  }
                  if (err.response && err.response.status === 481) {
                    this.errors = err.response.data.data.error;
                  }
                });
            } else {
              this.loading = false;
              return false;
            }
          });
        }
      },
      forceRender: function () {
        this.triggerRefreshOnComponent = this.triggerRefreshOnComponent + 1;
      },
      needsRefresh: function () {
        this.forceRender();
        this.$router.go();
      },
      async loginOmniChannel() {
        // check if user has SSO and is first time login
        const URLParams = new URLSearchParams(window.location.search);
        if (
          URLParams.has("source") &&
          URLParams.get("first_time_login") == "true"
        ) {
          this.$alert(
            "To complete Omni Channel Connection, please sign in with your phone number and password",
            {
              title: "Omni Channel Connection",
            }
          );
        }
        if (
          URLParams.has("source") &&
          URLParams.get("product") == "BMS" &&
          URLParams.get("first_time_login") == "false"
        ) {
          try {
            this.pageLoading = true;
            const data = await store.dispatch("auth/getTokenFromOmni", {
              email: URLParams.get("email"),
              product: URLParams.get("product"),
              email_hash: URLParams.get("source"),
            });
            if (data) {
              this.$mixpanel.track("Sign In (Omni Channel)", {
                Phone: data.phone,
                "User Type":
                  data.name == 0
                    ? "Account not activated"
                    : data.profile_type == 1
                    ? "Corporate"
                    : "Individual",
                "User Name": data.name,
                "Access Level": data.access_level == 4 ? "Admin" : "User",
                "User Status":
                  data.name !== 0
                    ? "Activated Account"
                    : "Account not activated",
                referrer: document.referrer,
              });
              this.$mixpanel.identify(data.phone);
              this.$mixpanel.people.set({
                $name: data.name,
                $phone: `+${data.phone}`,
                $email: data.email,
              });
              let storage = new Storage();
              if (data.is_active != 1) {
                store
                  .dispatch("auth/resendActivationCode", {
                    phone: data.phone,
                    otp_method: "sms",
                  })
                  .then(() =>
                    this.$router.push("/verify-account").catch((err) => {
                      if (err.name !== "NavigationDuplicated") throw err;
                    })
                  );
                return;
              }
              if (data.access_level == 4) {
                this.$router.push("/admin/users/all").catch((err) => {
                  if (err.name !== "NavigationDuplicated") throw err;
                });
                return;
              }
              if (data.profile_type == 1) {
                togglerCorporateStatus(true);
              } else {
                togglerCorporateStatus(false);
              }
              // verify whether default workspace has been check
              if (storage.getSavedState("defaultLoginType")) {
                let routeFrom = this.$route.query.returnUrl;
                if (routeFrom) {
                  return this.$router.push(routeFrom);
                }
                return this.$router.push("/overview");
              }
              this.$router.push("/login_type").catch((err) => {
                if (err.name !== "NavigationDuplicated") throw err;
              });
            }
          } catch (err) {
            this.$message({
              message: err.message,
              type: "error",
            });
            this.$mixpanel.track("OmniChannel Error", {
              error: err.response?.data,
            });
            if (err.response && err.response?.status === 404) {
              this.$message({
                message: err.response.data.data.error.split(".")[1],
                type: "error",
              });
            }
            if (err.response && err.response?.status === 480) {
              store
                .dispatch("auth/setNumberNeedToVerify", this.formData.phone)
                .then(() => {
                  this.$router.push("/verify-account").catch((err) => {
                    if (err.name !== "NavigationDuplicated") throw err;
                  });
                });
            }
            if (err.response && err.response?.status === 481) {
              this.$message({
                message: err.response.data.data.error,
                type: "error",
              });
            }
          } finally {
            this.pageLoading = false;
          }
        }
      },
    },
  };
</script>
<style scoped>
  /* #logo{
  width: 140px;
  height: 50px;
} */
  .button:hover,
  .button:active {
    border: none;
    outline: none;
  }

  .__sm {
    display: none;
  }

  .country_number_container {
    width: 100%;
    display: flex;
  }

  .country {
    width: 30%;
  }

  #form__inner {
    margin-top: -2em !important;
  }

  @media screen and (max-width: 768px) {
    #logo {
      width: 100px;
    }

    .addHeight {
      /* height: 700px !important; */
    }

    #mNotify__signin__form_fields_container {
      background-image: url("../../assets/tech.jpg");
      width: 100%;
      background-position: center;
      object-fit: cover;
      background-repeat: no-repeat;
      background-size: cover;
    }

    .mnotify_form__inner__wrapper {
      background-color: #fff;
      width: 60%;
      padding: 10px;
      margin: auto auto;
      /* height: 700px !important; */
    }

    .__margin_top {
      margin-top: 1em !important;
    }

    .__sm {
      display: block;
    }
  }

  @media screen and (max-width: 500px) {
    .mnotify_form__inner__wrapper {
      width: 90%;
    }
  }

  .support-link {
    color: #f79b31;
    cursor: pointer;
  }
</style>

<style scoped>
  .input-model {
    display: flex;
  }
</style>
